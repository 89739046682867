import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import ShopContext from '../context/ShopContext'
import Swal from 'sweetalert2'
import { navigate } from "@reach/router"  
import BlockContent from '../components/block-content/BlockContent'
import Slider from "react-slick"
import "../slick/slick.css"
import "../slick/slick-theme.css"
import SEO from '../components/seo'





const sanityConfig = {projectId: 'a3n0vinp', dataset: 'production'}




export const query = graphql`
query getProduct($slug: String!) {
    sanityFigure(slug: {current: {eq: $slug}}) {
        id
        isComplete
        name
        image {
            crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
                asset {
                    _id
                    url
            }
        }
        images {
            crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
                asset {
                    _id
                    url
            }
        }

        slug {
            current
        }
        price
        version
        year
        _rawDescription
        accessories
    }
}
`

const addToCart = (context, figure) => {
    context.addProductToCart(figure)
    Swal.fire({
        title: 'Produktet er lagt i handlekurven',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Gå til handlekurv',
        cancelButtonText: 'Fortsett å handle',
        
      }).then((result) => {
        if (result.isConfirmed) {
            navigate(`/handlekurv`)

        }
    })
  
}

const ProductTemplate = props => {
    const {data} = props

    const figure = data.sanityFigure
    const imageData = getGatsbyImageData(figure.image.asset, {maxWidth: 600}, sanityConfig)

    let gallery = []
    
    if (figure.images?.length > 0) {
        gallery.push(imageData)
        figure.images.forEach(x => {
            gallery.push(getGatsbyImageData(x.asset, {maxWidth: 600}, sanityConfig))
        })
    }

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
      
    
    
    return (
        <Layout>
            <SEO title={`${figure.name} ${figure.year}`} />
            <ShopContext.Consumer>
                {context => 
                <div className="container">
                    <div className="flex p-5 flex-col-reverse md:flex-row md:p-10 lg:p:20 xl:p-40">
                        <div className="w-full mt-10 md:mt-0 md:w-7/12">
                            <h1>{figure.name}</h1>
                            <p>Versjon: {figure.version}</p>
                            <p>År: {figure.year}</p>
                            <p>Pris: kr {figure.price}</p>

                            <div className="mt-10 mr-5">
                                {figure._rawDescription && (
                                    <BlockContent blocks={figure._rawDescription}/> 
                                )}
                                {figure.accessories?.length > 0 && (
                                    <div className="mt-5">
                                        <h3 className="font-semibold">Utstyr som følger med:</h3>
                                        {figure.accessories.map(accessory => (
                                            <p>- {accessory}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            
                            <div className="mt-10 items-center flex justify-center ">
                            <button 
                                onClick={() => addToCart(context, figure)}
                                className="focus:outline-none focus:bg-green-700 bg-green-500 hover:bg-green-700 text-white text-sm px-4 py-2 border rounded-full w-full md:w-3/6 xl:w-2/6 h-12"
                            >
                                Kjøp
                            </button>
                            </div>
                        </div>
                        <div className="w-full md:w-5/12">
                            {gallery.length === 0 && (
                                <GatsbyImage alt="Bilde av figur" image={imageData}/>
                            )}
                            {gallery.length > 0 && (
                                <Slider {...slickSettings} >
                                    {gallery.map(x => (
                                        <GatsbyImage alt="bilde av figur" key={x.name} image={x} />
                                    ))}
                                </Slider>
                        
                            )}

                        </div>
                    </div>
                </div>
                }
            </ShopContext.Consumer>
        </Layout>
    )
}

export default ProductTemplate